
.faq {
    padding-top: 50px;
    padding-bottom: 50px;
}

.faq h2 {
    font-size: 50px;
    text-align: center;
    font-weight: 500;

}

.faq .sub-head {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    margin-top: 10px;
}

.faq .main-d {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.faq .left {
    width: 50%;
}

.faq .right {
    width: 50%;
    padding-left: 50px;
    padding-top: 50px;
}
.acc .accordion-button{
    background: #f1f1f1;
    line-height: 2.6;
}
.acc .accordion-item{
    margin-bottom: 12px;
}
/*********************medium-desktop screen***********************/

@media (min-width: 1600px) and (max-width: 1699px) {

}

@media (min-width: 1200px) and (max-width: 1599px) {}

@media (min-width: 992px) and (max-width: 1199px) {

}


/*******************************responsive-tab****************************/

@media (min-width: 768px) and (max-width: 991px) {

}


/**********************************responsive-small-screen********************************/


/*responsive-small-screen*/

@media (max-width:767px) {

    .faq .left {
        width: 100%;
    }


    

}