.services {
    padding: 50px 0;
    background: #f8f8f8;
}

.services h2 {
    font-size: 35px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 40px;
}

.services h2::before {
    content: '';
    position: absolute;
    bottom: -10px;
    background: #09959f;
    width: 70px;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
}

.services ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.services ul li {
    width: 48%;
    margin: 1%;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.089);
    padding: 20px 30px;
    text-align: center;
    border-radius: 10px;
    background: #fff;

}

.services ul li h3 {
    padding: 10px 0;
    font-size: 20px;
}

.services ul li p {
    line-height: 23px;
    letter-spacing: 1px;
}

.services ul li img {
    width: 80px;
}

/*********************medium-desktop screen***********************/

@media (min-width: 1600px) and (max-width: 1699px) {
    .banner::before {
        height: 207px;
        bottom: 93px;
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {}

@media (min-width: 992px) and (max-width: 1199px) {
    .container {
        width: 920px;
    }

    .group_details .main-gr .left {
        width: 100%;
        text-align: center;
    }

    .group_details .main-gr .right {
        width: 100%;
    }

    .main_inner .swiper-button-next {
        right: 43.5%;
    }

    .main_inner .swiper-button-prev {
        left: 43.5%;
    }

}


/*******************************responsive-tab****************************/

@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 740px;
    }

    .form-main .form-inn input {
        width: 100%;
    }

    .main_inner .right .form-main .form-inn .btn-co {
        width: 100%;
    }

    .main_inner .swiper-button-next {
        right: 42%;
    }

    .main_inner .swiper-button-prev {
        left: 42%;
    }

    .invisalign .main-in .left p {
        padding-right: 9px;
        font-size: 16px;
    }

    .invisalign .main-in .right ul li {
        font-size: 16px;
    }

    .about .main-d .right p {
        font-size: 14px;
        line-height: 27px;
    }

    .about .main-d .right {
        margin-left: 3%;
    }

    .group_details .main-gr .left {
        width: 100%;
        text-align: center;
    }

    .group_details .main-gr .right {
        width: 100%;
    }

    .reg-now h2 {
        line-height: 43px;
        font-size: 28px;
    }
}


/**********************************responsive-small-screen********************************/


/*responsive-small-screen*/

@media (max-width:767px) {
    
    .services ul li{
        width: 100%;
    }
}