.desk_header {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: #000000e6;
    z-index: 2;
    display: none;
}

.desk_header .desk_head {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff;
    width: 300px;
    z-index: 999;
}

.clos_img {
    position: absolute;
    top: 5px;
    left: 8px;
    width: 22px;
    cursor: pointer;
    background: #fff;
    width: 30px;
    border-radius: 50%;
    padding: 6px;
}

.desk_header .desk_head ul li {
    list-style: none;
    text-align: center;
    margin: 9% 0;
    font-size: 16px;
    text-transform: capitalize;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 5px;
    transition: .5s;
}

.desk_header .desk_head ul li a {
    text-decoration: none;
    color: rgb(37, 37, 37);
}

.desk_header .desk_head ul li:hover {
    background: rgb(4, 1, 12);
}

.desk_header .desk_head ul li:hover a {
    color: #ffffff;
    padding: 5px 6px;
}



header {
    width: 100%;
    background: #fff;
    float: left;
    z-index: 2;
    position: fixed;
    top: 0;
    transition: all 0.4s ease-in-out;
    box-shadow: 0 -10px 18px #4f4f4f;

}


.mob-drop {
    display: none;
}


header.fixed {
    width: 100%;
    height: 66px;
    background-color: #fff;
    position: fixed;
    z-index: 4;
    box-shadow: 0 -10px 18px #4f4f4f;
}

header.fixed .fix_log {
    display: inline-block;
}

header.fixed .no-search li .drop-dn .service-drop {
    top: 53px;
}

header.fixed .nav-item a:hover {
    color: #F1612D;
}

header.fixed .navbar .social {
    margin-top: 5px;
}

header.fixed .navbar ul {
    margin-top: 12px;
    padding: 5px 0;
}



.navbar {
    width: 100%;
    float: left;
    margin: 5px 0;
}

.navbar img {
    width: 175px;
    float: left;
    transition: all ease .5s;
    margin-top: 10px;
}

.navbar img.fix_log {
    display: none;
}

header.fixed .navbar .blk_log {
    width: 115px;
}

header.fixed img.fix_log {
    display: inline-block;
    width: 115px;
}

.navbar img.nav_img {
    width: 35px;
    background: #3a1f71;
    padding: 7px;
    cursor: pointer;
}




.navbar .slider-logo {
    width: 46%;
    height: 0;
    float: left;
    position: absolute;
    top: 8px;
    left: 162px;
}

.navbar .slider-logo marquee {
    width: 20%;
    float: left;
    margin-left: 75px;
}

header .fixed .slider-logo marquee {
    margin-left: 40px;
}

.navbar .slider-logo img {
    width: 50px;
}

.navbar .slider-text {
    padding-top: 9px;
    float: left;
    margin-left: 10px;
}

.navbar .slider-text p {
    color: #fff;
    font-size: 12px;
    float: left;
}



.navbar ul {
    float: right;
    margin-top: 17px;
    margin-right: 20px;
    padding: 20px 0;
    transition: all ease .4s;
}

.navbar .soc_icons {
    float: right;
    margin-top: 9px;
    padding: 20px 0;
    transition: all ease .5s;
}

.navbar .nav-item:hover .fa-caret-down {
    transform: rotate(-180deg);
}

.navbar .nav-item .fa-caret-down {
    transition: all ease .5s;
    margin-left: 4px;
}

.navbar .social {
    float: right;
    margin-top: 10px;
    position: relative;
}

.navbar .social::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 71px;
    background-color: #fff;
    top: -20px;
}

.navbar .social .pop-req {
    width: 220px;
    display: none;
    position: absolute;
    overflow: hidden;
    z-index: 5;
    top: 35px;
    left: 53px;
    padding: 15px;
    background: #3c8716;
    border-radius: 4px;
    box-shadow: 0px 4px 6px #34303088;
}

.navbar .social .pop-req .inp {
    padding: 5px 0;
}

.navbar .social .pop-req .inp #opt_sel {
    width: 185px;
    border-radius: 4px;
    padding: 7px;
}

.navbar .social .pop-req .inp #opt_sel:focus-visible {
    outline: none;
}

.navbar .social .pop-req .inp input,
.navbar .social .pop-req .inp textarea {
    border-radius: 4px;
    padding: 7px;
    width: 185px;
}

.navbar .social .pop-req .inp textarea:focus {
    outline: none;
}

.navbar .social .pop-req .form-btn {
    text-align: center;
}

.navbar .social .pop-req .form-btn button {
    padding: 5px;
    width: 100px;
    font-weight: 500;
    border: 1px solid #3c8716;
    border-radius: 4px;
    background-color: #ac9104;
    color: #fff;
    font-size: 16px;
    transition: .4s;
    cursor: pointer;
}

.navbar .social .pop-req .form-btn button:hover {
    background-color: transparent;
    border: 1px solid #fff;
}

.navbar .social span.icon {
    float: left;
    background: #fff;
    margin-left: 12px;
    width: 30px;
    height: 30px;
    line-height: 33px;
    text-align: center;
    border-radius: 50%;
    transition: 0.2s;
    cursor: pointer;
}

.navbar .social i {
    color: #3c8716;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
}

.social .quote {
    background-color: #ac9104;
    border-radius: 8px;
    float: right;
    margin-left: 10px;
    padding: 6px;
    cursor: pointer;
    text-transform: uppercase;
}

.social .quote .request {
    color: #fff;
    padding-right: 14px;
}

.navbar .social i:hover {
    color: #fff;
}

.navbar .social span.icon:hover {
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.205);
    transform: scale(1.1);
    background-color: #000;
}

header.fixed .navbar .social span:hover i {
    color: #fff;
}

header.fixed .navbar .soc_icons {
    padding: 0;
}

.navbar .soc_icons a img {
    margin: 0 5px;
    width: 30px;
}

.navbar ul li {
    float: left;
    list-style: none;
}

.navbar ul li a.active {
    color: #F1612D;
}

.navbar ul li:first-child {
    display: none;
}

.nav-item a {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    text-decoration: none;
    transition: color 0.3s ease-out;
    padding: 10px 0 0;
    margin-left: 20px;
    padding-bottom: 30px;
}

.nav-item a:hover {
    color: #F1612D;
}

.slider-logo-head {
    float: left;
    width: 50px;
    margin-left: 10px;
    padding-top: 7px;
    position: relative;
}

.slider-logo-head::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 71px;
    background-color: #fff;
    top: -10px;
    left: -9px;
}

.slider-logo-head img {
    width: 40px;
}

#search-icon {
    font-size: 0.9rem;
    margin-top: 3px;
    margin-left: 15px;
    transition: color 0.3s ease-out;
}

#search-icon:hover {
    color: #3498db;
    cursor: pointer;
}

.search {
    transform: translate(-35%);
    -webkit-transform: translate(-35%);
    transition: transform 0.7s ease-in-out;
    color: #3498db;
}

.no-search {
    /* transform: translate(0); */
    transition: transform 0.7s ease-in-out;
}


/* dropdown menu */

.no-search li {
    /* position: relative; */
    display: inline-block;
    text-transform: capitalize;
}

.no-search li .drop-dn {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: .4s;
}

.no-search li .drop-dn .service-drop li:first-child {
    display: unset;
}

.no-search li .drop-dn .service-drop {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    top: 88px;
    left: 0;
    z-index: 5;
    display: flex;
    background-color: #00817e;
    padding-left: 50px;
}

.no-search li:hover .drop-dn .service-drop {
    height: 290px;
}

.no-search .nav-item:hover .drop-dn {
    opacity: 1;
    visibility: visible;
}

.no-search li .drop-dn .service-drop::after {
    position: absolute;
    /* content: ''; */
    width: 100%;
    height: 5px;
    bottom: 0;
    background: linear-gradient(to right, #f58634 20%, #847059 20%, #847059 40%, #ffdd21 40%, #ffdd21 60%, #45b653 60%, #45b653 80%, #f8ac92 80%, #f8ac92 100%);
}

.no-search li .drop-dn .service-drop .drop-inner {
    display: flex;
}

.no-search li .drop-dn .service-drop li {
    width: 100%;
    /* font-weight: 400; */
    /* background: #3c8716; */
    transition: all ease .4s;
    padding: 0;
    text-align: center;
}

.no-search li .drop-dn .service-drop li figure {
    text-align: center;
    cursor: pointer;
    padding-top: 70px;
    padding-top: 50px;
    display: none;
}

.no-search li .drop-dn .service-drop li figure img {
    width: 60px;
}

.no-search li .drop-dn .service-drop li a {
    margin: 0;
    font-size: 18px;
}

.no-search li .drop-dn .service-drop li ul.list {
    margin: 0;
    margin-left: 10px;
}

.no-search li .drop-dn .service-drop li ul.list li {
    padding: 0;
    padding-top: 7px;
    color: #fff;
    text-align: left;
}

.no-search li .drop-dn .service-drop li ul.list li h3 {
    font-size: 18px;
    color: #fbd13d;
}

.no-search li .drop-dn .service-drop li ul.list li a {
    font-size: 14px;
    text-transform: none;
    color: #fff;
}

.no-search li .drop-dn .service-drop li ul.list li a:hover {
    color: #fbd13d;
}

.search-input {
    position: absolute;
    top: -4px;
    right: -125px;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.6s ease;
}

.search-active {
    opacity: 1;
    z-index: 0;
}

input {
    border: 0;
    border-left: 1px solid #ccc;
    border-radius: 0;
    outline: 0;
    padding: 5px;
}


/* MOBILE MENU & ANIMATION */

.menu-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: #7b664f;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


/* .fixed .menu-toggle .bar {
    background-color: #fff;
} */

.menu-toggle {
    justify-self: end;
    margin-right: 25px;
    display: none;
}

.menu-toggle:hover {
    cursor: pointer;
}

#mobile-menu.is-active .bar:nth-child(2) {
    opacity: 0;
}

#mobile-menu.is-active .bar:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
    -ms-transform: translateY(8px) rotate(45deg);
    -o-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
}

#mobile-menu.is-active .bar:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    -ms-transform: translateY(-8px) rotate(-45deg);
    -o-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
}


/* KEYFRAME ANIMATIONS */

@-webkit-keyframes gradbar {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes gradbar {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes gradbar {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

.head-margin {
    height: 100px;
}

/*********************medium-desktop screen***********************/

@media (min-width: 1600px) and (max-width: 1699px) {
    .banner::before {
        height: 207px;
        bottom: 93px;
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {}

@media (min-width: 992px) and (max-width: 1199px) {
    .container {
        width: 920px;
    }

}


/*******************************responsive-tab****************************/

@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 740px;
    }


}


/**********************************responsive-small-screen********************************/


/*responsive-small-screen*/

@media (max-width:767px) {
    .header-mar {
        height: 89px;
    }

    .container {
        width: 90%;
        padding: 0;
    }

    .navbar .soc_icons {
        display: none;
    }

    .mob_nav_scroll {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    /* MOBILE HEADINGS */
    h1 {
        font-size: 1.9rem;
    }

    h2 {
        font-size: 1rem;
    }

    p {
        font-size: 0.8rem;
    }

    /* MOBILE NAVIGATION */
    header.fixed {
        background: #fff;
        top: -2px;
    }

    .navbar img {
        width: 150px;

        margin: auto;
        float: none;
    }

    .navbar ul {
        display: flex;
        flex-direction: column;
        position: fixed;
        justify-content: start;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 70%;
        height: 100vh;
        transform: translate(-101%);
        box-shadow: 0 4px 7px #80808088;
        text-align: center;
        overflow: auto;
        z-index: 999;
        margin-top: 0;
        padding: 0;
    }

    .navbar ul.mob-drop.trans {
        transform: translate(0);
        width: 100%;
    }

    .mb_10 {
        margin-bottom: 9px;
    }

    .navbar ul::before {
        position: absolute;
        content: '';
    }

    .navbar .nav-item {
        display: block;
        text-align: left;
    }

    .navbar img.nav_img {
        display: none;
    }

    .navbar li {
        padding: 15px;
    }

    .navbar ul li:first-child {
        display: block;
        border-bottom: 1px solid #fff;
    }

    .navbar li a {
        font-size: 1rem;
        color: #000;
    }

    .slider-logo-head {
        display: none;
    }

    .navbar ul li .mob-drop img {
        width: 35px;
        margin: 1% 0;
    }

    .navbar .slider-text {
        display: none;
    }

    .navbar .social {
        display: none;
    }

    .navbar ul li .mob-drop {
        width: 100%;
        overflow: auto;
        text-align: left !important;
    }

    .navbar ul li {
        text-align: left;
    }

    .navbar ul li .mob-drop .fa-long-arrow-left {
        float: right;
        margin-top: 5px;
        margin-right: 30px;
    }

    .navbar ul li .mob-drop li {
        padding: 5px;
    }

    .navbar ul li .mob-drop li a {
        font-size: 0.8rem;
        padding-bottom: 10px;
        float: left;
        margin-left: 5px;
        padding: 5px;

    }

    .navbar ul li .mob-drop li figure a {
        float: none;
    }

    header.fixed .navbar ul {
        margin: 0;
    }

    header.fixed .menu-toggle {
        top: 20px;
    }

    .back-opt {
        display: initial;
        float: right;

    }

    .menu-toggle {
        display: block;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 50%;
        transition: .5s;
        transform: translateY(-50%);
        z-index: 2;
    }

    .bar {
        display: block;
    }

    .mobile-nav {
        transform: translate(0%) !important;
    }

    .no-search li:hover .drop-dn .service-drop {
        height: 0;
    }


    header ul li:last-child a:last-child {
        width: 210px;
        height: 38px;
        line-height: 18px;
    }
    .head-margin{
        height: 75px;
    }

}