

footer {
    width: 100%;
    float: left;
    background: #f2f2f2;
}

footer .foot-2 {
    width: 100%;
    float: left;
    padding: 50px 0 0;
}

footer .inner-foot-2 h2 {
    font-size: 22px;
    text-transform: uppercase;
    color: #038792;
    margin: 20px 0;
}

footer .inner-foot-2 li {
    font-size: 14px;
    line-height: 28px;
    position: relative;
    margin-bottom: 10px;

    list-style: none;
}

footer .inner-foot-2 img {
    width: 201px;
}

footer .inner-foot-2 li::before {
    position: absolute;
    content: "\f105";
    left: -11px;
    top: 6px;
    font: normal normal normal 13px/1 FontAwesome;
    width: 20px;
    height: 20px;
    font-weight: 800;
    color: #038792;
}

footer .foot-2 p {
    color: rgb(43, 43, 43);
    font-size: 12px;
    padding: 10px 0;
    line-height: 22px;
}

footer .foot-2 .inner-foot-2 {
    width: 25%;
    float: left;
    padding: 8px 0 39px;
}

footer .foot-2 .inner-foot-2:nth-child(4) {

    border-bottom: 0;

}

footer .foot-2 .inner-foot-2:nth-child(3) li {
    margin-bottom: 5px;

}

footer .foot-2 .inner-foot-2:nth-child(2) {
    padding-left: 20px;
}

footer .foot-2 .inner-foot-2:nth-child(2) li {
    margin-left: 15px;
}

footer .foot-2 .inner-foot-2:nth-child(3) a:before {
    border-bottom: 0;

}



footer .foot-2 .inner-foot-2 a {
    color: rgb(45, 45, 45);
    position: relative;
    transition: .5s;
}

footer .foot-2 .inner-foot-2 a:hover {
    color: rgb(45, 45, 45);
}

footer .foot-2 .inner-foot-2 figure {
    margin-top: 20px;
}

footer .foot-2 .inner-foot-2 a:before {
    position: absolute;
    content: '';
    width: 132px;
    margin: -7px 0;
    bottom: 0;
}

footer .foot-2 .inner-foot-2 a:before:first-child {
    border-bottom: none;
}

footer .foot-2 .inner-foot-2:nth-child(4) a::before,
footer .foot-2 .inner-foot-2:nth-child(1) a::before {
    border-bottom: 0;
}

footer .foot-2 .inner-foot-2:nth-child(4) li::before,
footer .foot-2 .inner-foot-2:nth-child(1) li:before {
    content: '';
}

footer .foot-2 .inner-foot-2 .social i {
    color: #000000;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-left: 2px;
    margin-top: 5px;
    transition: 0.4s;
    background: #038792;
}

footer .foot-copyright {
    width: 100%;
    float: left;
    color: rgb(232, 232, 232);
    font-size: 12px;
    padding: 13px 0;
    background: #038792;
    text-align: center;
}
footer .foot-copyright p{
    color: #f2f2f2;
    margin-bottom: 0;
}
footer .foot-copyright .cpy-txt {
    color: rgb(255, 255, 255);
    text-align: center;
}
a{
    text-decoration: none;
}



/*********************medium-desktop screen***********************/

@media (min-width: 1600px) and (max-width: 1699px) {

}

@media (min-width: 1200px) and (max-width: 1599px) {}

@media (min-width: 992px) and (max-width: 1199px) {
   

}


/*******************************responsive-tab****************************/

@media (min-width: 768px) and (max-width: 991px) {
   
}

/**********************************responsive-small-screen********************************/


/*responsive-small-screen*/

@media (max-width:767px) {

    footer .foot-2 .inner-foot-2 {
        width: 100%;
        padding-bottom: 0;
        margin-bottom: 2px;
    }

    footer .foot-2 .inner-foot-2:nth-child(2) {
        padding-left: 0;
    }
    footer .foot-2 .inner-foot-2 ul{
        margin-bottom: 0;
    }
    footer .inner-foot-2 li {
        margin-left: 10px;

    }
    

}