.about-upper h2::before{
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%,-50%);
    background: rgb(115, 115, 115);
    width: 50px;
    height: 2px;
}

/*********************medium-desktop screen***********************/

@media (min-width: 1600px) and (max-width: 1699px) {
    .banner::before {
        height: 207px;
        bottom: 93px;
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {}

@media (min-width: 992px) and (max-width: 1199px) {
   

}


/*******************************responsive-tab****************************/

@media (min-width: 768px) and (max-width: 991px) {
    
}


/**********************************responsive-small-screen********************************/


/*responsive-small-screen*/

@media (max-width:767px) {
   

    .about .main-d {
        flex-direction: column;
    }

    .about .main-d .right,
    .about .main-d .left,
    .how_it_works .left {
        width: 100%;
    }

   

}