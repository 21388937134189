.banner {
    position: relative;
}

.banner .seo_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;

}

.banner .seo_text h1 {
    font-size: 60px;
    color: #fff;
    text-shadow: 0 2px 13px rgba(0, 0, 0, 0.712);
}

.banner .seo_text span {
    display: block;
    color: #fff;
}

.banner .seo_text p {
    color: #fff;
}

.banner .btn-co {
    margin: 12px 0;

}
.banner a{
    color: #fff;
}
/*********************medium-desktop screen***********************/

@media (min-width: 1600px) and (max-width: 1699px) {
}

@media (min-width: 1200px) and (max-width: 1599px) {}

@media (min-width: 992px) and (max-width: 1199px) {
   

}


/*******************************responsive-tab****************************/

@media (min-width: 768px) and (max-width: 991px) {
   
}


/**********************************responsive-small-screen********************************/


/*responsive-small-screen*/

@media (max-width:767px) {
    .bann_form {
        width: 100%;
        margin-top: 35px;
    }

    .bann_form .btn-top {
        width: 100%;
    }
    .banner .seo_text h1{
        font-size: 35px;
    }
    .banner .seo_text span{
        display: unset;
    }
    .banner .seo_text{
        width: 100%;
        text-align: center;
    
    }
    .banner .btn-co{
        display: flex;
        margin: auto;
        justify-content: center;
    }

}