.about {
    padding: 50px 0;
    background: #fcfcfc;
}

.about h2 {
    font-size: 40px;
    text-transform: uppercase;
    text-align: left;
    position: relative;
    margin-bottom: 40px;
}

.about p {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 23px;
}

.about h2::before {
    content: '';
    position: absolute;
    bottom: -10px;
    background: #09959f;
    width: 60px;
    height: 3px;
    left: 0;
}

.about .main-d {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.about .main-d .left {
    width: 48%;

}

.about .main-d .right {
    width: 48%;
}
.about-us h3{
    font-size: 16px;
    font-weight: 700;
}
.about-us-sec.why-choose ul li{
    width: 50%;
    padding: 20px 10px 0;
}
