.how_it_works {
    padding: 50px 20px;

}

.how_it_works h2 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 40px;
}

.how_it_works .para {
    text-align: center;
    margin: auto;
}

.how_it_works .main-d {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: space-between;
}

.how_it_works .left {
    width: 76%;
    padding-left: 100px;
}

.how_it_works .left img {
    width: 100px;
}

.how_it_works .right {
    width: 20%;
}

.how_it_works .left ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;


}

.how_it_works .left ul li {
    width: 49%;
    margin-bottom: 2%;
    box-shadow: 0 3px 20px #ccc;
    border-radius: 10px;
    padding: 20px;
}

.how_it_works .left ul li h3 {
    font-size: 22px;
    margin: 10px 0;
}
/*********************medium-desktop screen***********************/

@media (min-width: 1600px) and (max-width: 1699px) {
}

@media (min-width: 1200px) and (max-width: 1599px) {}

@media (min-width: 992px) and (max-width: 1199px) {

}


/*******************************responsive-tab****************************/

@media (min-width: 768px) and (max-width: 991px) {
  
}


/**********************************responsive-small-screen********************************/


/*responsive-small-screen*/

@media (max-width:767px) {
    
    .how_it_works h2{
        font-size: 25px;
    }
    .how_it_works .left{
        padding-left: 0;
    }

}