
.enquiry-form {
    padding: 100px 25px;
   
    background: url(../images/bg-cont.jpg) no-repeat;
    background-size: cover;
    
    overflow: hidden;
}

.left-text {
    width: 50%;
    float: left;
    display: none;
}

.left-text figure {
    float: right;
    width: 100%;
}

.left-text h1 {
    font-size: 36px;
    margin-bottom: 15px;
    width: 100%;
    color: #fff;
}

.left-text p {
    font-size: 18px;
    color: #fff;
}

.left-text ul {
    width: 100%;
    float: left;
    margin-top: 50px;
    list-style: none;
}

.left-text ul li i.fa-facebook {
    color: #fff;
    background-color: #3a559f;
    font-size: 50px;
    padding: 10px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 87px;
    transition: .4s;
}

.left-text ul li i.fa-facebook:hover {
    background-color: #000;
}

.right-form {
    width: 70%;
    padding: 23px 26px;
    padding-left: 0;
    background-color: #0000008e;
    border-radius: 4px;
    display: table;
    margin: auto;
    border: 1px solid #444;
}

.right-form .heading {
    text-transform: capitalize;
    padding: 10px 0;
    padding-top: 0;
    margin-left: 25px;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
}

.right-form .heading h2 {
    color: #fff;
    font-size: 40px;
    padding-bottom: 6px;
}

.right-form .heading p {
    padding: 15px 0;
}

.right-form form input::placeholder,
.right-form form textarea::placeholder {
    color: #ffffff;
}

.right-form form input,
.right-form form textarea {
    color: #fff;
    width: calc(50% - 25px);
    float: left;
    height: 50px;
    padding: 10px;
    background: #0000003b;
    margin-left: 25px;
    margin-bottom: 25px;
    border: 1px solid #ccc;
}

.right-form form input:focus,
.right-form form textarea:focus {
    color: #fff;
    outline: 1px solid;
    border: none;
}

.right-form form input.phone {
    width: 97%;
}

.right-form form textarea {
    width: 97%;
    height: 100px;
}

.g-recaptcha {
    padding-left: 26px;
    width: 100%;
    float: left;
}

.right-form form button {
    width: 200px;
    margin: 22px 0 0;
    border: 1px solid #000000;
    margin-left: 25px;
    font-size: 18px;
    text-transform: uppercase;
    color: #111111;
    cursor: pointer;
    height: 50px;
    background: #fff;
    position: relative;
    z-index: 0;
}

.right-form form button::before {
    position: absolute;
    content: '';
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all ease .4s;
    background-color: #050505;
}

.right-form form button:hover {
    border: 1px solid #fff;
    color: #fff;
}

.right-form form button:hover::before {
    width: 100%;
    transition-timing-function: cubic-bezier(.52, 1.64, .37, .66);
}

/*********************medium-desktop screen***********************/

@media (min-width: 1600px) and (max-width: 1699px) {

}

@media (min-width: 1200px) and (max-width: 1599px) {}

@media (min-width: 992px) and (max-width: 1199px) {


}


/*******************************responsive-tab****************************/

@media (min-width: 768px) and (max-width: 991px) {

}


/**********************************responsive-small-screen********************************/


/*responsive-small-screen*/

@media (max-width:767px) {
    
    .enquiry-form{
        padding: 100px 0;
    }
    .right-form{
        width: 100%;
    }
    .right-form form input, .right-form form textarea,
    .right-form form input, .right-form form input.phone{
        width: 95%;
    }
    

}