.why-us {
    padding: 50px 0;
    position: relative;
    background: #008795;
}

.why-us .img {
    width: 400px;
    display: table;
    margin: 30px auto 0;
}

.why-us .main-d {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.why-us .main-d ul {
    width: 45%;
    margin-top: 10px;
}

.why-us .main-d ul li {
    margin-bottom: 10px;
    line-height: 23px;
    position: relative;
    font-weight: 600;
    opacity: 1;
    color: #fff;
}

.why-us .main-d ul li::before {
    position: absolute;
    content: "\f058";
    font: normal normal normal 14px/1 FontAwesome;
    width: 40px;
    height: 24px;
    left: -17px;
    top: 5px;
    color: #ffffff;
}
    

.why-us h2 {
    position: relative;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
    color: #fff;
}

.why-us h2::before {
    content: '';
    position: absolute;
    bottom: -10px;
    background: #09959f;
    width: 60px;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
}


/*********************medium-desktop screen***********************/

@media (min-width: 1600px) and (max-width: 1699px) {
    
}

@media (min-width: 1200px) and (max-width: 1599px) {}

@media (min-width: 992px) and (max-width: 1199px) {
  

}


/*******************************responsive-tab****************************/

@media (min-width: 768px) and (max-width: 991px) {
   
}


/**********************************responsive-small-screen********************************/


/*responsive-small-screen*/

@media (max-width:767px) {
   
    .why-us h2{
        font-size: 25px;
    }
    .why-us .main-d ul{
        width: 100%;
       margin-left: 20px;
    }
    

}